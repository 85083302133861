import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content } from './overview/style';
import Heading from '../../../components/heading/heading';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={24}>
          <Aside>
            <div className="auth-side-content">
              <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" />
              <Content>
                {/* <img style={{ width: '150px' }} src={require('../../../static/img/dine_easy_bg.jpeg')} alt="" /> */}
                <br />
                <br />
                <Heading as="h1">
                <img style={{ width: '250px' }} src={require('../../../static/img/logo_de_full.png')} className="centerLogo" alt="" />
                <br />
                  <br />
                  {/* Smart Prism Admin <br />
                  Web Application */}
                </Heading>
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
