import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import  ProtectedRoute  from './components/utilities/protectedRoute'; 
import 'antd/dist/antd.less';
import { getBaseURL } from './Api/ApiConfig'

const { theme } = config;

function ProviderConfig() {
  const [states, setStates] = useState()
  const [resStatus, setResStatus] = useState()
  const { rtl, topMenu, darkMode } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,

    };
  });

  const [path, setPath] = useState(window.location.pathname);
  const isLoggedIn = localStorage.getItem('admin_token');
  
  useEffect(() => {
    const headers = {};
    headers.Authorization = `Bearer ${isLoggedIn}`;
    async function getAdminSource() {
      if (isLoggedIn !== undefined && isLoggedIn !== null) {
        const data = await axios.get(`${getBaseURL()}/smartPrism/getAdminByToken`, { headers })
        if (data.data.statusCode === 200) {
          setStates(data.data.data.source)

          if (data.data.data.source === "SUB_ADMIN") {
            const response = await axios.get(`${getBaseURL()}/smartPrism/getRestaurantDetailBySubAdmin`, { headers })
            setResStatus(response.data.data.restaurant.restaurantStatus)
            localStorage.setItem("admin_ResStatus", response.data.data.restaurant.restaurantStatus);
            if (response.data.data.restaurant.restaurantStatus === "INACTIVE") {
              toast.error("Your Restaurant has been InActive");
              window.localStorage.removeItem('admin_token');
              window.localStorage.removeItem('admin_expiration');
            }
          }
        }
      }
    }
    getAdminSource();

    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);

  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>

          {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/admins" component={Admin} />}

          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) &&
            states === "SUB_ADMIN" ? (resStatus === "ACTIVE" ? <Redirect to="/admins" /> : <Redirect to="/" />)
            : <Redirect to="/admins" />
          }
        </Router>

      </ThemeProvider>
    </ConfigProvider >
  );
}

function App() {
  return (
    <>
      <Provider store={store}>
        <ProviderConfig />
      </Provider>
      <ToastContainer
        autoClose={3000}
        position="top-right"
        theme="light"
        style={{ width: "300px" }}
      />
    </>
  );
}

export default hot(App);
