import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/dashboard'));
const QrCOdeGenerate = lazy(() => import('../../container/dashboard/qrCodeGenerate'));
const AddRestaurant = lazy(() => import('../../container/dashboard/addRestaurant'));
const AddSubAdmin = lazy(() => import('../../container/dashboard/addSubAdmin'));
const AddSteward = lazy(() => import('../../container/dashboard/addSteward'));
const ViewRestaurants = lazy(() => import('../../container/dashboard/viewRestaurants'))
const SubadminResView = lazy(() => import('../../container/dashboard/s_adminViewRes'))
const ViewSubAdmins = lazy(() => import('../../container/dashboard/viewSubadmin'))
const ViewStewards = lazy(() => import('../../container/dashboard/viewSteward'))
const AssignSteward = lazy(() => import('../../container/dashboard/assignSteward'))
const AddShift = lazy(() => import('../../container/dashboard/addShift'))
const AddMainCategory = lazy(() => import('../../container/dashboard/addMainCategory'))
const addSubCategory = lazy(() => import('../../container/dashboard/addSubCategory'))
const ViewShifts = lazy(() => import('../../container/dashboard/viewShift'))
const AssignTable = lazy(() => import('../../container/dashboard/assignTable'))
const MenuView = lazy(() => import('../../container/dashboard/menuView'))
const PriceConfig = lazy(() => import('../../container/dashboard/priceConfig'))
const PriceConfigList = lazy(() => import('../../container/dashboard/PriceConficList'))
const Settings = lazy(() => import('../../container/dashboard/settings'));
const Guests = lazy(() => import('../../container/dashboard/Guests'));
const Orders = lazy(() => import('../../container/dashboard/Orders'));
const Profile = lazy(() => import('../../container/dashboard/Profile'));
const OrderDetail = lazy(() => import('../../container/dashboard/orderDetail'));
const CreateOrder = lazy(() => import('../../container/dashboard/createOrder'));
const RestaurantBillSettings = lazy(() => import('../../container/dashboard/RestaurantBillSettings'));
const TaxSettings= lazy(() => import('../../container/dashboard/TaxSettings'));
 const AssignStewardToTable = lazy(() => import('../../container/dashboard/assignStewardToTable'));
const Bills= lazy(() => import('../../container/dashboard/Bills'));
const Invoice= lazy(() => import('../../container/dashboard/Invoice'));
const UpdateMenu = lazy(()=>import('../../container/dashboard/restaurentUpdateMenu'))
const AddMenu = lazy(()=>import('../../container/dashboard/restaurentAddMenu'))
function DashboardRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/qrGenerate`} component={QrCOdeGenerate} />
      <Route path={`${path}/addRestaurant`} component={AddRestaurant} />
      <Route path={`${path}/Restaurants`} component={ViewRestaurants} />
      <Route path={`${path}/Restaurant`} component={SubadminResView} />
      <Route path={`${path}/addSubAdmin`} component={AddSubAdmin} />
      <Route path={`${path}/SubAdmin`} component={ViewSubAdmins} />
      <Route path={`${path}/Menu`} component={MenuView} />
      <Route path={`${path}/addShift`} component={AddShift} />
      <Route path={`${path}/addMainCategory`} component={AddMainCategory} />
      <Route path={`${path}/updateMenu`} component={UpdateMenu} />
      <Route path={`${path}/addSubCategory`} component={addSubCategory} />
      <Route path={`${path}/addMenu`} component={AddMenu} />
      <Route path={`${path}/shifts`} component={ViewShifts} />
      <Route path={`${path}/addSteward`} component={AddSteward} />
      <Route path={`${path}/Steward`} component={ViewStewards} />
      <Route path={`${path}/assignSteward`} component={AssignSteward} />
      <Route path={`${path}/assignTable`} component={AssignTable} />
      <Route path={`${path}/add-priceConfig`} component={PriceConfig} />
      <Route path={`${path}/priceConfig`} component={PriceConfigList} />
      <Route path={`${path}/Settings`} component={Settings} />
      <Route path={`${path}/Guests`} component={Guests} />
      <Route path={`${path}/Orders`} component={Orders} />
      <Route path={`${path}/order-detail`} component={OrderDetail} />
      <Route path={`${path}/create-order`} component={CreateOrder} />
      <Route path={`${path}/Profile`} component={Profile} />
      <Route path={`${path}/RestaurantBillSettings`} component={RestaurantBillSettings} />
      <Route path={`${path}/TaxSettings`} component={TaxSettings} />
      <Route path={`${path}/assign-steward-to-table`} component={AssignStewardToTable} />
     <Route path={`${path}/Bills`} component={Bills} />
     <Route path={`${path}/Invoice`} component={Invoice} />

    </Switch>
  );
}

export default DashboardRoutes;
