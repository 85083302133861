import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { getBaseURL } from '../Api/ApiConfig';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const [state, setState] = useState()
  const [stewardType, setStewardType] = useState()
  const [resType, setResType] = useState()

  useEffect(() => {
    const adminLoginToken = localStorage.getItem('admin_token');
    const headers = {};
    headers.Authorization = `Bearer ${adminLoginToken}`;
    async function getAdminSource() {
      const data = await axios.get(`${getBaseURL()}/smartPrism/getAdminByToken`, { headers })
      if (data.data.statusCode === 200) {
        setState(data.data.data.source)
        if (data.data.data.source === "SUB_ADMIN") {
          const response = await axios.get(`${getBaseURL()}/smartPrism/getRestaurantDetailBySubAdmin`, { headers })
          setResType(response.data.data.restaurant.category)
          setStewardType(response.data.data.restaurant.callStewardOption)
        }
      }
    }
    getAdminSource();
  }, [resType])

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >

      {state !== undefined && <>
        <Menu.Item key="dashboard" icon={<NavLink className="menuItem-iocn" to={`${path}`} >

          <FeatherIcon icon="cpu" />
        </NavLink>}>
          <NavLink onClick={toggleCollapsed} to={`${path}`} >

            Dashboard
          </NavLink>
        </Menu.Item>



        <Menu.Item key="qrGenerate" icon={ <NavLink className="menuItem-iocn" to={`${path}/qrGenerate`} >
        <FeatherIcon icon="maximize" />
          </NavLink>}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/qrGenerate`} >
            Generate QrCode
          </NavLink>
        </Menu.Item>

        {/* {state === 'SUB_ADMIN' ? <></> :
          <Menu.Item key="addRestaurant">
            <NavLink onClick={toggleCollapsed} to={`${path}/addRestaurant`}>
              Add Restaurant
            </NavLink>
          </Menu.Item>} */}

        {state === 'SUB_ADMIN' ?
          <Menu.Item key="viewRestaurant" icon={<NavLink className="menuItem-iocn" to={`${path}/Restaurant`}>
          <FeatherIcon icon="home" />
        </NavLink>}>
            <NavLink onClick={toggleCollapsed} to={`${path}/Restaurant`}>
              Restaurant
            </NavLink>
          </Menu.Item>
          :
          <Menu.Item key="viewRestaurant" icon={<NavLink className="menuItem-iocn" to={`${path}/Restaurants`}>
          <FeatherIcon icon="home" />
        </NavLink>}>
            <NavLink onClick={toggleCollapsed} to={`${path}/Restaurants`}>
              Restaurants
            </NavLink>
          </Menu.Item>}

        <Menu.Item key="menuList" icon={<NavLink className="menuItem-iocn" to={`${path}/Menu`} >
        <FeatherIcon icon="shopping-cart" />
          </NavLink>} >
          <NavLink onClick={toggleCollapsed} to={`${path}/Menu`} >
            Menu List
          </NavLink>
        </Menu.Item>
        {/* {state === 'SUB_ADMIN' ? <></> :
          <Menu.Item key="addSubAdmin">
            <NavLink onClick={toggleCollapsed} to={`${path}/addSubAdmin`}>
              Add SubAdmin
            </NavLink>
          </Menu.Item>} */}

        {state === 'SUB_ADMIN' ? <></> :
          <Menu.Item key="ViewSubAdmin" icon={<NavLink className="menuItem-iocn" to={`${path}/SubAdmin`}>
          <FeatherIcon icon="user-plus" />
        </NavLink>}>
            <NavLink onClick={toggleCollapsed} to={`${path}/SubAdmin`}>
              SubAdmins
            </NavLink>
          </Menu.Item>}

        {/* {state === 'SUB_ADMIN' ?
          <Menu.Item key="addSteward">
            <NavLink onClick={toggleCollapsed} to={`${path}/addSteward`}>
              Add Steward
            </NavLink>
          </Menu.Item> : <></>} */}

        {state === 'SUB_ADMIN' && stewardType === "ENABLED" &&
          <Menu.Item key="viewSteward" icon={<NavLink className="menuItem-iocn" to={`${path}/Steward`}>
          <FeatherIcon icon="users" />
        </NavLink>}>
            <NavLink onClick={toggleCollapsed} to={`${path}/Steward`}>
              Stewards
            </NavLink>
          </Menu.Item>}

        {state === 'SUB_ADMIN' && resType !== "Menu" ?
          <Menu.Item key="viewShift" icon={ <NavLink className="menuItem-iocn" to={`${path}/shifts`}>
          <FeatherIcon icon="repeat" />
        </NavLink>}>
            <NavLink onClick={toggleCollapsed} to={`${path}/shifts`}>
              Shifts
            </NavLink>
          </Menu.Item> : <></>}

        {state === 'SUB_ADMIN' && resType !== "Menu" ?
          <Menu.Item key="assignSteward" icon={<NavLink className="menuItem-iocn" to={`${path}/assignSteward`}>
          <FeatherIcon icon="user-check" />
        </NavLink>}>
            <NavLink onClick={toggleCollapsed} to={`${path}/assignSteward`}>
              Assign Stewards
            </NavLink>
          </Menu.Item> : <></>}

        {state === 'SUB_ADMIN' &&
          <Menu.Item key="priceConfig" icon={<NavLink className="menuItem-iocn" to={`${path}/priceConfig`}>
          <FeatherIcon icon="dollar-sign" />
        </NavLink>}>
            <NavLink onClick={toggleCollapsed} to={`${path}/priceConfig`}>
              Price Config
            </NavLink>
          </Menu.Item>}



        {state === 'SUB_ADMIN' &&
          <Menu.Item key="guests" icon={<NavLink className="menuItem-iocn" to={`${path}/guests`} >
          <FeatherIcon icon="user" />
        </NavLink>}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/guests`} >
              Guests
            </NavLink>
          </Menu.Item>
        }
        {/* {state === 'SUB_ADMIN' &&
<Menu.Item key="restaurantBillSettings" icon={<FeatherIcon icon="user" />}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/restaurantBillSettings`} >
          Restaurant Bill Settings
          </NavLink>
        </Menu.Item> 
} */}
        {/* {state === 'SUB_ADMIN' &&
<Menu.Item key="TaxSettings" icon={<FeatherIcon icon="user" />}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/TaxSettings`} >
          Tax Settings
          </NavLink>
        </Menu.Item> 
} */}



        {state === 'SUB_ADMIN' &&
          <Menu.Item key="orders" icon={<NavLink className="menuItem-iocn" to={`${path}/orders`} >
          <FeatherIcon icon="shopping-cart" />
        </NavLink>}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/orders`} >
              Orders
            </NavLink>
          </Menu.Item>

        }
        {state === 'SUB_ADMIN' &&
          <Menu.Item key="Bills" icon={ <NavLink className="menuItem-iocn" to={`${path}/bills`} >
          <FeatherIcon icon="user" />
        </NavLink>}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/bills`} >
              Bills
            </NavLink>
          </Menu.Item>
        }
        <Menu.Item key="settings" icon={<NavLink className="menuItem-iocn" to={`${path}/Settings`} >
        <FeatherIcon icon="settings" />
          </NavLink>}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/Settings`} >
            Settings
          </NavLink>
        </Menu.Item>
      </>

      }
    </Menu>



  );
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;

