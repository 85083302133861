import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
// import Message from './message';
// import Notification from './notification';
// import Settings from './settings';
// import Support from './support';
import { Popover } from '../../popup/popup';
// import { Dropdown } from '../../dropdown/dropdown';
import { getBaseURL } from '../../../Api/ApiConfig';
// import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';

function AuthInfo() {
  // const dispatch = useDispatch();
  const history = useHistory();
  const [states, setStates] = useState()
  const [resName, setResName] = useState()
  const [resImage, setResImage] = useState()
  // const [state, setState] = useState({
  //   flag: 'english',
  // });
  // const { flag } = state;

  const SignOut = () => {
    // e.preventDefault();
    window.localStorage.removeItem('admin_token');
    window.localStorage.removeItem('admin_expiration');
    history.push('/');
    window.location.reload()
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {states !== "SUB_ADMIN" ?
            <img src={require('../../../static/img/icon/addResturant.png')} style={{ width: "50px", height: "50px" }} alt="" />
            : <img src={resImage} style={{ width: "50px", height: "50px" }} alt="" />
          }
          <figcaption>
            <Heading as="h5">{states !== "SUB_ADMIN" ? "SPRISM" : resName} </Heading>
            <p>{states !== "SUB_ADMIN" ? "Master Admin" : "Resturant Admin"}</p>
          </figcaption>

        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/admins/profile">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link to = "/admins/Settings" >
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li> */}
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  // const onFlagChangeHandle = value => {
  //   setState({
  //     ...state,
  //     flag: value,
  //   });
  // };

  // const country = (
  //   <NavAuth>
  //     <Link onClick={() => onFlagChangeHandle('english')} to="#">
  //       <img src={require('../../../static/img/flag/english.png')} alt="" />
  //       <span>English</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('germany')} to="#">
  //       <img src={require('../../../static/img/flag/germany.png')} alt="" />
  //       <span>Germany</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('spain')} to="#">
  //       <img src={require('../../../static/img/flag/spain.png')} alt="" />
  //       <span>Spain</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('turky')} to="#">
  //       <img src={require('../../../static/img/flag/turky.png')} alt="" />
  //       <span>Turky</span>
  //     </Link>
  //   </NavAuth>
  // );

  useEffect(() => {

    const adminLoginToken = localStorage.getItem('admin_token');
    const headers = {};
    headers.Authorization = `Bearer ${adminLoginToken}`;
    async function getAdminSource() {
      const data = await axios.get(`${getBaseURL()}/smartPrism/getAdminByToken`, { headers })
      if (data.data.statusCode === 200) {
        setStates(data.data.data.source)
        if (data.data.data.source === "SUB_ADMIN") {
          const response = await axios.get(`${getBaseURL()}/smartPrism/getRestaurantDetailBySubAdmin`, { headers })
          setResName(response.data.data.restaurant.restaurantName)
          setResImage(response.data.data.restaurant.resLogo)
        }
      }
    }
    getAdminSource();
  }, [states, resName])

  return (
    <InfoWraper>
      {/* // <Message />
     //<Notification /> */}

      {/* <Settings />
      <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
              {states !== "SUB_ADMIN" ?
            <Avatar  src={require('../../../static/img/icon/addResturant.png')} style={{ width: "50px", height: "45px" }} alt=""/>
            : <Avatar  src={resImage} style={{ width: "50", height: "45px" }} alt="" />
        }
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
